import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

interface Configuration {
  apiUrl: string;
  siteId: string;
  trackerUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private readonly configUrl = 'assets/config/config.json';
  private configuration$: Observable<Configuration> | undefined;
  public apiUrl: string = '';
  public siteId: string = '';
  public trackerUrl: string = 'https://analytics.geact.ipe.df.gov.br';

  constructor(private http: HttpClient, private matomoTracker: MatomoTracker) {}

  public load(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http
        .get<Configuration>(`${this.configUrl}`)
        .pipe(shareReplay(1));

      this.configuration$.subscribe((config: Configuration) => {
        this.siteId = config.siteId;
        this.trackerUrl = config.trackerUrl
          ? config.trackerUrl
          : this.trackerUrl;
        this.apiUrl = config.apiUrl;
        this.matomoTracker.setTrackerUrl(this.trackerUrl);
        this.matomoTracker.setSiteId(config.siteId);
      });
    }

    return this.configuration$;
  }
}
