import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { EnvConfigService } from './env-config.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://analytics.geact.ipe.df.gov.br', siteId: '' }),
    NgxMatomoRouterModule,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (envConfigService: EnvConfigService) => () => envConfigService.load().toPromise(),
    deps: [EnvConfigService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
